import axios from "axios";
import { config } from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

class StaticActionsService {
  getAllStaticActions(url) {
    return axios.get(API_URL + url, {
      headers: authHeader(),
    });
  }
  getStaticAction(id) {
    return axios.get(API_URL + id, {
      headers: authHeader(),
    });
  }
  addStaticAction(url, setting) {
    return axios.post(API_URL + url, setting, {
      headers: authHeader(),
    });
  }
  updateStaticAction(url, setting) {
    return axios.put(API_URL + url, setting, {
      headers: authHeader(),
    });
  }
  deleteStaticActions(url) {
    return axios.delete(API_URL + url, {
      headers: authHeader(),
    });
  }
}
export default new StaticActionsService();
